import React from 'react';
import { Col, Row } from 'react-grid-system';
import { Link } from 'gatsby';

function HeroCtaHCP({
	imgIcon,
	linkUrl,
	linkText,
	iconArrowsDots,
	className,
	children,
	...props
}) {
	return (
		<div {...props} className={`${className ? className : ''}`}>
			<>
				<Row>
					<Col xs={2}>
						<div className='cta-icon-wrapper'>
							<Link to={linkUrl}>
								<img src={imgIcon} alt='' />
							</Link>
						</div>
					</Col>
					<Col xs={10}>
						<div className='cta-link-wrapper'>
							<Link
								to={linkUrl}
								dangerouslySetInnerHTML={{ __html: linkText }}
							/>
							<Link to={linkUrl}>
								<img src={iconArrowsDots} className='dots-arrow' alt='' />
							</Link>
							{children}
						</div>
					</Col>
				</Row>
			</>
		</div>
	);
}

export default HeroCtaHCP;
